<template>
  <div>
    <v-dialog
      v-model="isVisibleDialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="$admin.can('data-sims.create')"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          @click="!isVisibleDialog"
        >
          {{ $t("add_new") }}
        </v-btn>
      </template>

      <v-form
        ref="form"
        lazy-validation
      >
        <v-card>
          <v-card-title>
            <span class="text-h3">{{ $t("add_new") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="dataSim.country_code"
                    dense
                    :items="countryCodeLists"
                    item-value="id"
                    item-text="name"
                    :label="$t('country_code_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="dataSim.msisdn"
                    dense
                    type="number"
                    :label="$t('msisdn_*')"
                    :rules="[
                      rules.required,
                      rules.startFrom(3),
                      rules.min_length(10),
                      rules.max_length(10),
                    ]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="dataSim.network"
                    dense
                    :items="networkLists"
                    item-value="id"
                    item-text="name"
                    :label="$t('network_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="dataSim.is_active"
                    dense
                    :items="statusLists"
                    item-value="id"
                    item-text="name"
                    :label="$t('status_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="dataSim.sim_imei"
                    dense
                    type="number"
                    :label="$t('sim_imsi_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="dataSim.notes"
                    dense
                    :label="$t('notes')"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <small>{{ $t("indicates_required_field") }}</small>
            <v-spacer />
            <v-btn
              color="red darken-1"
              text
              @click="closeForm()"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              text
              :loading="isLoadingSave"
              @click="saveItem(dataSim)"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],

  data() {
    return {
      isVisibleDialog: false,
      isLoadingSave: false,
      dataSim: {},
      networkLists: [
        {
          id: "telenor",
          name: "Telenor",
        },
        {
          id: "ufone",
          name: "Ufone",
        },
        {
          id: "jazz",
          name: "Jazz",
        },
        {
          id: "warid",
          name: "Warid",
        },
      ],
      statusLists: [
        {
          id: "1",
          name: "Active",
        },
        {
          id: "0",
          name: "Inactive",
        },
      ],
      countryCodeLists: [
        {
          id: "+92",
          name: "+92",
        },
      ],
    };
  },

  methods: {
    closeForm() {
      this.$refs.form.reset();
      this.isVisibleDialog = false;
    },
    async saveItem(dataSim) {
      if (this.$refs.form.validate()) {
        this.isLoadingSave = true;
        await this.$store
          .dispatch("dataSims/add", dataSim)
          .then(() => {
            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
            this.isLoadingSave = false;
            this.closeForm();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
            this.isLoadingSave = false;
          });
      }
    },
  },
};
</script>
